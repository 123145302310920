import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// import { useQuery } from "react-query";
import toast from "react-hot-toast";

import { fetchMatchDateListApi, updateMatchDateApi } from "../api";
import { listMatchDateAction } from "../redux/slices/matchesDateSlice";

function* fetchMatchDateListSaga(action) {
  try {
    const res = yield call(fetchMatchDateListApi, { params: action.payload });

    if (res?.data) {
      yield put(
        listMatchDateAction.fetchMatchDateListSuccess(res?.data?.matchesDate)
      );
    }
  } catch (error) {
    console.log(error.message);
    toast.error("Get data errors !");
  }
}

function* updateMatchDateSaga(action) {
  try {
    const res = yield call(updateMatchDateApi, { params: action.payload });

    if (res?.data) {
      yield put(
        listMatchDateAction.updateMatchDateSuccess(res?.data?.updateMatchDate)
      );
      toast.success("Updated success !");
    } else {
      toast.error("Updated errors !");
    }
  } catch (error) {
    toast.error("Updated errors !");
  }
}

export default function* reportSaga() {
  yield takeEvery(
    listMatchDateAction.fetchMatchDateList.type,
    fetchMatchDateListSaga
  );
  yield takeLatest(
    listMatchDateAction.updateMatchDate.type,
    updateMatchDateSaga
  );
}
