import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client';
import { API_URL_BASE } from "../constants";

const uploadLink = createUploadLink({
  uri: API_URL_BASE
});

// No use cache apollo
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

export const apolloClient = new ApolloClient({
  link: uploadLink,
  cache: new InMemoryCache({
    addTypename: false,
    freezeResults: false,
  }),
  assumeImmutableResults: false,
  defaultOptions: defaultOptions
});