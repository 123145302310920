import { apolloClient } from "../graphql/apolloClient";
import { FETCH_TEAMS } from "../graphql/teams";


export function fetchTeamListApi({token, params}) {
  return apolloClient.query({
    query: FETCH_TEAMS,
    variables: params
  });
}
