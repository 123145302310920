import { gql } from "@apollo/client";

export const FETCH_HISTORY_PLAN_BY_USER = gql`
  query historyByUserPlans ($id: ID!){
    historyByUserPlans(id: $id) {
      id
      userId
      date
      value
      plan
      daysPlan
      adminPayment
      status
    }
  }
`;

export const URL_UPDATE_HISTORY_PLAN_ADMIN = gql`
  mutation updateHistoryPlanAdmin($id: ID!, $input: InputHistoryPlan){
    updateHistoryPlanAdmin(id: $id, input: $input)
    {
      data { id, userId, date, unit, plan, daysPlan, expiredPlan, value, status },
      ok,
      error
    }
  }
`;

export const URL_DELETE_HISTORY_PLAN_ADMIN = gql`
  mutation deleteHistoryPlanAdmin($id: ID!){
    deleteHistoryPlanAdmin(id: $id)
    {
      data { id, userId, date, unit, plan, daysPlan, expiredPlan, value, status },
      ok,
      error
    }
  }
`;

