import { gql } from "@apollo/client";

export const FETCH_PAYMENTS = gql`
  query payments($limit: Int) {
    payments (limit: $limit){
      id
      account
      apiToken
      paymentType
      amount
      selected
      isNew
      note
      times
      status
    }
  }
`;

// query {
//   leagues(limit:1000) {
//     id, 
//     name, 
//     footballId, 
//     type, 
//     logo, 
//     logoLink, 
//     country { name, code, flag }
//   }
// }

export const FETCH_PAYMENTS_BY_ID = gql`
  query payment($id: ID!) {
    payment(id: $id){
      id
      account
      apiToken
      paymentType
      amount
      selected
      isNew
      times
      note
      status
    }
  }
`;


export const CREATE_PAYMENT = gql`
  mutation createPayment($input: InputPayment) {
    createPayment(input: $input){
      data { 
        id
        account
        apiToken
        paymentType
        amount
        selected
        isNew
        times
        note
        status
      }
      ok
      error
    }
  }
`;

export const UPDATE_PAYMENT = gql`
  mutation updatePayment($id: ID!, $input: InputPayment) {
    updatePayment(id: $id, input: $input){
      data { 
        id
        account
        apiToken
        paymentType
        amount
        selected
        isNew
        times
        note
        status
      }
      ok
      error
    }
  }
`;
export const DELETE_PAYMENT = gql`
  mutation deletePayment($id: ID!) {
    deletePayment(id: $id){
      data { 
        id
        account
        apiToken
        paymentType
        amount
        selected
        isNew
        times
        status
      }
      ok
      error
    }
  }
`;
