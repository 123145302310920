import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  teams: [],
}

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    fetchTeamList(state, action) {},

    fetchTeamListSuccess(state, action) {
      state.teams = action.payload;
    }
  },
});

// Actions
export const teamAction = teamSlice.actions;

// Reducer
const teamReducer = teamSlice.reducer;
export default teamReducer;