import { apolloClient } from "../graphql/apolloClient";
import { UPDATE_GENERAL, FETCH_GENERALS } from "../graphql/general";

export function fetchGeneralApi({ params }) {
  return apolloClient.query({
    query: FETCH_GENERALS,
    variables: params,
  });
}

export function updateGeneralApi({ params }) {
  return apolloClient.mutate({
    mutation: UPDATE_GENERAL,
    variables: params,
    context: {
      headers: {
        'Content-Type': "application/json"
      },
    },
  });
}
