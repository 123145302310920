import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { fetchTeamListApi } from "../api";
import { teamAction } from "../redux/slices/teamSlice";

function* fetchTeamListSaga(action) {
  try {
    const res = yield call(fetchTeamListApi, { params: action.payload });
    yield put(teamAction.fetchTeamListSuccess(res?.data?.teams));
  } catch (error) {
    // failedToast(error, errCts.LOGIN_FAILED);
  }
}

export default function* reportSaga() {
  yield takeEvery(teamAction.fetchTeamList.type, fetchTeamListSaga);
}
