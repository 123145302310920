import { gql } from "@apollo/client";

export const FETCH_USERS = gql`
  query {
    users {
      id
      fullName
      avatar
      email
      provider_pic
      plan
      expiredPlan
      historyPayments { userId, value }
      status
    }
  }
`;

export const FETCH_USER_BY_ID = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      fullName
      avatar
      provider_pic
      email
      plan
      expiredPlan
      status
    }
  }
`;

export const UPDATE_USER_BY_ID = gql`
  mutation updateUserDetail($id: ID!, $input: InputUser) {
    updateUserDetail(id: $id, input: $input ) {
      data {
        id
        fullName
        avatar
        provider_pic
        email
        plan
        expiredPlan
        historyPayments { userId, value }
        status
      }
      ok
      error
    }
  }
`;
