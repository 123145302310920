import { gql } from "@apollo/client"

export const FETCH_LEAGUES = gql`
  query {
    leagues(limit:1000) {
      id, 
      name, 
      footballId, 
      type, 
      logo, 
      logoLink, 
      country { name, code, flag }
    }
  }
`;
