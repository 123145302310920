import { apolloClient } from "../graphql/apolloClient";
import {
  FETCH_USERS,
  FETCH_USER_BY_ID,
  UPDATE_USER_BY_ID,
} from "../graphql/users";

export function fetchUserListApi(token, params) {
  return apolloClient.query({
    query: FETCH_USERS,
  });
}
export function fetchUserByIdApi({ params }) {
  return apolloClient.query({
    query: FETCH_USER_BY_ID,
    variables: params,
  });
}

export function updateUserDetailApi({ token, params }) {
  return apolloClient.mutate({
    mutation: UPDATE_USER_BY_ID,
    variables: params,
  });
}
