import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  matchDateList: [],
}

const listMatchDateSlice = createSlice({
  name: 'match-date',
  initialState,
  reducers: {
    fetchMatchDateList(state, action) {},

    fetchMatchDateListSuccess(state, action) {
      state.matchDateList = action.payload;
    },

    updateMatchDate(state, action){},

    updateMatchDateSuccess(state, action){
      const findIndex = state.matchDateList.findIndex(item => item.footballId === action.payload?.data?.footballId);

      if(findIndex > -1){

        if(action.payload?.data?.isHot !== undefined){
          state.matchDateList[findIndex].isHot = action.payload?.data?.isHot;
        }

        if(action.payload?.data?.liveLinks !== undefined){
          state.matchDateList[findIndex].liveLinks = action.payload?.data?.liveLinks;
        }

        if(action.payload?.data?.highlights !== undefined){
          state.matchDateList[findIndex].highlights = action.payload?.data?.highlights;
        }

        state.matchDateList = [...state.matchDateList];
      }
    }
  },
});

// Actions
export const listMatchDateAction = listMatchDateSlice.actions;

// Selectors
export const selectMatchDateList = (state) => state.users.userList;
// Reducer
const listMatchesDateReducer = listMatchDateSlice.reducer;
export default listMatchesDateReducer;