import { apolloClient } from "../graphql/apolloClient";
import { FETCH_LEAGUES } from "../graphql/leagues";


export function fetchLeaguesApi({token, params}) {
  return apolloClient.query({
    query: FETCH_LEAGUES,
    variables: params
  });
}
