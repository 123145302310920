import { apolloClient } from "../graphql/apolloClient";
import { FETCH_MATCHES_DATE, UPDATE_MATCHES_DATE } from "../graphql/matchesDate";

// import request from "../api/axiosServices";

export function fetchMatchDateListApi({token, params}) {
  return apolloClient.query({
    query: FETCH_MATCHES_DATE,
    variables: params,
  });
}

// export function fetchMatchDateListApi({token, params}) {
//   return request({
//     url: "",
//     method: "POST",
//     data: {
//       query: FETCH_MATCHES_DATE
//     }
//   });
// }

export function updateMatchDateApi({token, params}) {
  return apolloClient.mutate({
    mutation: UPDATE_MATCHES_DATE,
    variables: params
  });
}