import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { fetchCompetitionListApi } from "../api";
import { competitionAction } from "../redux/slices/competitionSlice";

function* fetchCompetitionListSaga(action) {
  try {
    const res = yield call(fetchCompetitionListApi, { params: action.payload });
    yield put(competitionAction.fetchCompetitionListSuccess(res?.data?.competitions));
  } catch (error) {
    // failedToast(error, errCts.LOGIN_FAILED);
  }
}

export default function* reportSaga() {
  yield takeEvery(competitionAction.fetchCompetitionList.type, fetchCompetitionListSaga);
}
