import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  payments: [],
  payment: null
}

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    fetchPayments(state, action) {
      // state.payments = [];
    },

    fetchPaymentsSuccess(state, action) {
      state.payments = action.payload;
    },

    fetchPaymentById(state, action) {
      state.payment = null;
    },

    fetchPaymentByIdSuccess(state, action) {
      state.payment = action.payload;
    },

    createPayment(state, action) {
    },

    createPaymentSuccess(state, action) {
      state.payments = [action.payload, ...state.payments];
    },

    updatePayment(state, action) {
      state.payment = null;
    },

    updatePaymentSuccess(state, action) {
      state.payment = action.payload;
    },

    deletePayment(state, action) {
    },

    deletePaymentSuccess(state, action) {
      state.payments = state?.payments.filter(item => item.id !== action.payload.id);
    }
  },
});

// Actions
export const {
  fetchPaymentById,
  fetchPaymentByIdSuccess,
  fetchPayments,
  fetchPaymentsSuccess,
  createPayment,
  createPaymentSuccess,
  updatePayment,
  updatePaymentSuccess,
  deletePayment,
  deletePaymentSuccess
} = paymentSlice.actions;

// Reducer
const paymentReducer = paymentSlice.reducer;
export default paymentReducer;
