import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  userAdminList: []
}

const listUserAdminSlice = createSlice({
  name: 'userAdmin',
  initialState,
  reducers: {
    fetchUserAdminList(state, action) {},

    fetchUserAdminListSuccess(state, action) {
      state.bookList = action.payload;
    }
  },
});

// Actions
export const userAdminListAction = listUserAdminSlice.actions;

// Selectors
export const selectUserAdminList = (state) => state.userAdmin.userAdminList;

// Reducer
const listUserAdminReducer = listUserAdminSlice.reducer;
export default listUserAdminReducer;
