import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import toast from "react-hot-toast";

import {
  fetchPaymentsApi,
  fetchPaymentByIdApi,
  createPaymentApi,
  updatePaymentApi,
  deletePaymentApi
} from "../api";

import { 
  fetchPayments,
  fetchPaymentsSuccess,
  fetchPaymentById,
  fetchPaymentByIdSuccess,
  createPayment,
  createPaymentSuccess,
  updatePayment,
  updatePaymentSuccess,
  deletePayment,
  deletePaymentSuccess
 } from "../redux/slices/paymentSlice";

function* fetchPaymentsSaga(action) {
  try {
    const res = yield call(fetchPaymentsApi, { params: action.payload });
    yield put((fetchPaymentsSuccess(res?.data?.payments)));
  } catch (error) {
    toast.error("Get errors !");
  }
}

function* fetchPaymentByIdSaga(action) {
  try {
    const res = yield call(fetchPaymentByIdApi, { params: action.payload });
    yield put(fetchPaymentByIdSuccess(res?.data?.user));
  } catch (error) {
    toast.error("Get errors !");
  }
}

function* createPaymentSaga(action) {
  try {
    const res = yield call(createPaymentApi, { params: action.payload });

    const { data, errors } = res;
    
    if (data?.createPayment?.ok) {
      toast.success("Created success");
      yield put(createPaymentSuccess(data?.createPayment?.data));
    } else {
      if (data.createPayment?.error) {
        toast.error(`${data.createPayment?.error}`);
      } else {
        toast.error(`${errors[0].message}`);
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}


function* deletePaymentSaga(action) {
  try {
    const res = yield call(deletePaymentApi, { params: action.payload });

    const { data, errors } = res;
    
    if (data.deletePayment?.ok) {
      toast.success("Deleted Success");
      yield put(deletePaymentSuccess(data?.deletePayment?.data));

    } else {
      if (data.deletePayment?.error) {
        toast.error(`${data.deletePayment?.error}`);
      } else {
        toast.error(`${errors[0].message}`);
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}
function* updatePaymentSaga(action) {
  try {
    const res = yield call(updatePaymentApi, { params: action.payload });

    const { data, errors } = res;
    
    if (data.updatePayment?.ok) {
      toast.success("Updated Success");
      yield put(updatePaymentSuccess(data?.updatePayment?.data));
    } else {
      if (data.updatePayment?.error) {
        toast.error(`${data.updatePayment?.error}`);
      } else {
        toast.error(`${errors[0].message}`);
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

export default function* reportSaga() {
  yield takeEvery(fetchPayments.type, fetchPaymentsSaga);
  yield takeEvery(fetchPaymentById.type, fetchPaymentByIdSaga);
  yield takeLatest(createPayment.type, createPaymentSaga);
  yield takeLatest(deletePayment.type, deletePaymentSaga);
  yield takeLatest(updatePayment.type, updatePaymentSaga);
}
