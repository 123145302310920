import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { fetchLeaguesApi } from "../api";
import { LeaguesAction } from "../redux/slices/leagueSlice";

function* fetchLeaguesSaga(action) {
  try {
    const res = yield call(fetchLeaguesApi, { params: action.payload });
    yield put(LeaguesAction.fetchLeaguesListSuccess(res?.data?.leagues));
  } catch (error) {
    // failedToast(error, errCts.LOGIN_FAILED);
  }
}

export default function* reportSaga() {
  yield takeEvery(LeaguesAction.fetchLeaguesList.type, fetchLeaguesSaga);
}
