import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import toast from "react-hot-toast";

import {
  fetchHistoryPlanByUserApi,
  updateHistoryPlanAdminApi,
  deleteHistoryPlanAdminApi
} from "../api";
import { 
  fetchHistoryPlanByUser, 
  fetchHistoryPlanByUserSuccess, 
  updateHistoryPlanAdmin, 
  updateHistoryPlanAdminSuccess,
  deleteHistoryPlanAdmin,
  deleteHistoryPlanAdminSuccess 
} from "../redux/slices/historyPlan";

function* fetchHistoryPlanByUserSaga(action) {
  try {
    const res = yield call(fetchHistoryPlanByUserApi, action.payload);
    yield put(fetchHistoryPlanByUserSuccess(res?.data?.historyByUserPlans));
  } catch (error) {
    toast.error("Get errors !");
  }
}

function* updateHistoryPlanAdminSaga(action) {
  try {
    const res = yield call(updateHistoryPlanAdminApi, action.payload);

    const { data, errors } = res;

    if(data.updateHistoryPlanAdmin?.ok){
      toast.success("Success")
      yield put(updateHistoryPlanAdminSuccess(data?.updateHistoryPlanAdmin));
    }else{
      toast.error(`${errors[0].message}`);
    }
  
  } catch (error) {
    toast.error(error?.message);
  }
}

function* deleteHistoryPlanAdminSaga(action) {
  try {
    const res = yield call(deleteHistoryPlanAdminApi, action.payload);

    const { data, errors } = res;

    if(data.deleteHistoryPlanAdmin?.ok){
      toast.success("Success")
      yield put(deleteHistoryPlanAdminSuccess(data?.deleteHistoryPlanAdmin));
    }else{
      toast.error(`${errors[0].message}`);
    }
  
  } catch (error) {
    toast.error(error?.message);
  }
}

export default function* reportSaga() {
  yield takeEvery(fetchHistoryPlanByUser.type, fetchHistoryPlanByUserSaga);
  yield takeLatest(updateHistoryPlanAdmin.type, updateHistoryPlanAdminSaga);
  yield takeLatest(deleteHistoryPlanAdmin.type, deleteHistoryPlanAdminSaga);
}
