import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  leagues: [],
}

const LeaguesSlice = createSlice({
  name: 'Leagues',
  initialState,
  reducers: {
    fetchLeaguesList(state, action) {},

    fetchLeaguesListSuccess(state, action) {
      state.leagues = action.payload;
    }
  },
});

// Actions
export const LeaguesAction = LeaguesSlice.actions;

// Reducer
const LeaguesReducer = LeaguesSlice.reducer;
export default LeaguesReducer;