import { gql } from "@apollo/client"

export const FETCH_MATCHES_DATE = gql`
  query matchesDate($limit: Int, $startDate: String, $endDate: String, $competitionFootballId: Int, $status: String) {
    matchesDate(limit: $limit, startDate: $startDate, endDate: $endDate, competitionFootballId: $competitionFootballId, status: $status) {
      id,
      footballId, 
      homeTeam { 
        name, crest 
      },
      awayTeam {
        name, crest
      },
      competition {
        name
      },
      utcDate,
      venue,
      minute,
      isHot,
      liveLinks {
        lang,
        link
      },
      highlights {
        lang,
        link
      },
      status
    }
  }
`;

export const UPDATE_MATCHES_DATE = gql`
  mutation updateMatchDate($footballId: Int, $isHot: Boolean, $liveLinks: String, $highlights: String){
    updateMatchDate(footballId: $footballId, isHot: $isHot, liveLinks: $liveLinks, highlights: $highlights)
    {
      data {
        footballId, 
        isHot,
        liveLinks {
          lang, link
        }
        highlights {
          lang, link
        }
      }, 
      ok,
      error
    }
  }
`;
