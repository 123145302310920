import { apolloClient } from "../graphql/apolloClient";

import {
  FETCH_PAYMENTS,
  FETCH_PAYMENTS_BY_ID,
  UPDATE_PAYMENT,
  CREATE_PAYMENT,
  DELETE_PAYMENT
} from "../graphql/payments";

export function fetchPaymentsApi({token, params}) {
  return apolloClient.query({
    query: FETCH_PAYMENTS,
    variables: params
  });
}
export function fetchPaymentByIdApi({ params }) {
  return apolloClient.query({
    query: FETCH_PAYMENTS_BY_ID,
    variables: params,
  });
}

export function createPaymentApi({ token, params }) {
  return apolloClient.mutate({
    mutation: CREATE_PAYMENT,
    variables: params,
  });
}

export function updatePaymentApi({ token, params }) {
  return apolloClient.mutate({
    mutation: UPDATE_PAYMENT,
    variables: params,
  });
}

export function deletePaymentApi({ token, params }) {
  return apolloClient.mutate({
    mutation: DELETE_PAYMENT,
    variables: params,
  });
}
