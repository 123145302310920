import { apolloClient } from '../graphql/apolloClient';
import {
  FETCH_HISTORY_PLAN_BY_USER,
  URL_UPDATE_HISTORY_PLAN_ADMIN,
  URL_DELETE_HISTORY_PLAN_ADMIN
} from '../graphql/historyPlan';

export function fetchHistoryPlanByUserApi(params) {
  return apolloClient.query({
    query: FETCH_HISTORY_PLAN_BY_USER,
    variables: params,
  });
}

export function updateHistoryPlanAdminApi(params) {
  return apolloClient.mutate({
    mutation: URL_UPDATE_HISTORY_PLAN_ADMIN,
    variables: params,
  });
}

export function deleteHistoryPlanAdminApi(params) {
  return apolloClient.mutate({
    mutation: URL_DELETE_HISTORY_PLAN_ADMIN,
    variables: params,
  });
}
