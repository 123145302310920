import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  general: null
}

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    fetchGeneral(state, action) {},
    fetchGeneralsSuccess(state, action) {
      state.general = action.payload;
    },

    updateGeneral(state, action){},
    updateGeneralSuccess(state, action){
      state.general = action.payload;
    }
  },
});

// Actions
export const { 
  fetchGeneral,
  fetchGeneralsSuccess,
  updateGeneral,
  updateGeneralSuccess
 } = generalSlice.actions;

// Reducer
const generalReducer = generalSlice.reducer;
export default generalReducer;