import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  competitionList: [],
}

const competitionSlice = createSlice({
  name: 'competition',
  initialState,
  reducers: {
    fetchCompetitionList(state, action) {},

    fetchCompetitionListSuccess(state, action) {
      state.competitionList = action.payload;
    }
  },
});

// Actions
export const competitionAction = competitionSlice.actions;

// Reducer
const competitionReducer = competitionSlice.reducer;
export default competitionReducer;