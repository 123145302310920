import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import toast from "react-hot-toast";

import {
  fetchUserListApi,
  fetchUserByIdApi,
  updateUserDetailApi,
} from "../api";
import { userListAction } from "../redux/slices/userSlide";

function* fetchUserListSaga(action) {
  try {
    const res = yield call(fetchUserListApi);
    yield put(userListAction.fetchUserListSuccess(res?.data?.users));
  } catch (error) {
    toast.error("Get users errors !");
  }
}

function* fetchUserByIdSaga(action) {
  try {
    const res = yield call(fetchUserByIdApi, { params: action.payload });
    yield put(userListAction.fetchUserByIdSuccess(res?.data?.user));
  } catch (error) {
    toast.error("Get user errors !");
  }
}

function* updateUserSaga(action) {
  try {
    const res = yield call(updateUserDetailApi, { params: action.payload });

    const { data, errors } = res;
    
    if (data.updateUserDetail?.ok) {
      toast.success("Updated Success");
      yield put(userListAction.updateUserByIdSuccess(data?.updateUserDetail?.data));

    } else {
      if (data.updateUserDetail?.error) {
        toast.error(`${data.updateUserDetail?.error}`);
      } else {
        toast.error(`${errors[0].message}`);
      }
      // yield put(hideLoading(false));
    }
  } catch (error) {
    // yield put(hideLoading(false));
    toast.error(error?.message);
  }
}

export default function* reportSaga() {
  yield takeEvery(userListAction.fetchUserList.type, fetchUserListSaga);
  yield takeEvery(userListAction.fetchUserById.type, fetchUserByIdSaga);
  yield takeLatest(userListAction.updateUserById.type, updateUserSaga);
}
