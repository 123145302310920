import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  historiesPlan: [],
  historyPlan: null,
  deletedHistory: null
};

const historyPlanSlice = createSlice({
  name: 'historyPlan',
  initialState,
  reducers: {
    fetchHistoryPlanByUser(state, action) {},

    fetchHistoryPlanByUserSuccess(state, action) {
      state.historiesPlan = action.payload;
    },

    updateHistoryPlanAdmin(state, action) {},
    updateHistoryPlanAdminSuccess(state, action) {
      const index = state.historiesPlan.findIndex(
        (i) => i.id === action.payload?.data?.id
      );
      if (index !== -1) {
        state.historiesPlan[index] = action.payload.data;
      }

      state.historyPlan = action.payload.data;
    },

    deleteHistoryPlanAdmin(state, action) {},
    deleteHistoryPlanAdminSuccess(state, action) {
      const filterData = state.historiesPlan.filter(
        (i) => i.id !== action.payload?.data?.id
      );

      state.historiesPlan = filterData;
      state.deletedHistory = action.payload?.data;
    },
  },
});

// Actions
export const {
  fetchHistoryPlanByUser,
  fetchHistoryPlanByUserSuccess,
  updateHistoryPlanAdmin,
  updateHistoryPlanAdminSuccess,
  deleteHistoryPlanAdmin,
  deleteHistoryPlanAdminSuccess
} = historyPlanSlice.actions;

// Reducer
const historyPlanReducer = historyPlanSlice.reducer;
export default historyPlanReducer;
