import { apolloClient } from "../graphql/apolloClient";
import { FETCH_COMPETITIONS } from "../graphql/competition";


export function fetchCompetitionListApi({token, params}) {
  return apolloClient.query({
    query: FETCH_COMPETITIONS,
    variables: params
  });
}
