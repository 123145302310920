import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userList: [],
  user: null
}

const listUserSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUserList(state, action) {},

    fetchUserListSuccess(state, action) {
      state.userList = action.payload;
    },

    fetchUserById(state, action) {
    },

    fetchUserByIdSuccess(state, action) {
      state.user = action.payload;
    },

    updateUserById(state, action) {
    },

    updateUserByIdSuccess(state, action) {
      state.user = action.payload;
    }
  },
});

// Actions
export const userListAction = listUserSlice.actions;

// Selectors
export const selectUserList = (state) => state.users.userList;
// Reducer
const listUserReducer = listUserSlice.reducer;
export default listUserReducer;
